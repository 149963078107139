import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from '@mui/material/Tooltip';
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import PersonIcon from '@mui/icons-material/Person';
import AspectRatioRoundedIcon from "@mui/icons-material/AspectRatioRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useNavigate } from "react-router-dom";
import PsychologyIcon from '@mui/icons-material/Psychology';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { IoSettings } from "react-icons/io5";
import BugReportIcon from '@mui/icons-material/BugReport';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
 
 
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
 
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
 
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
 
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
 
const paths = [
  {
    path: "/dashboard",
    label: "Dashboard",
  },
  {
    path: "/user",
    label: "Users",
  },
  {
    path: "/project",
    label: "Projects",
  },
  {
    path: "/project_management",
    label: "Project Environment",
  },
  {
    path: "/suites",
    label: "Suites",
  },
  {
    path: "/report_analytics",
    label: "Reporting & Analytics",
  },
  {
    path: "/testcases/all",
    label: "Test Cases",
  },
  {
    path: "/TestCaseReport",
    label: "Test Cases Reports",
  },
  {
    path: "/SuiteReport",
    label: "Suite Reports",
  },
  {
    path: "/SchedularReport",
    label: "Schedular Report",
  },
  {
    path: "/add-user",
    label: "Assign Projects",
  },
  {
    path: "/Scheduler",
    label: "Schedule Task",
  },
  {
    path: "/files",
    label: "Files",
  },

  {
    path: "/stored-data",
    label: "Stored data",
  },

  {
    path: "/ai-test",
    label: "AI Test",
  },
  {
    path: "/CeleryRestart",
    label: "Setting",
  },
  {
    path: "/Jira",
    label: "Jira",
  },
];
 
export const SideBar = ({
  open,
  handleDrawerClose,
  setMainLayout,
  mainLayout,
  orgName,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [userdata]  = useState(JSON.parse(localStorage.getItem('authentication')));
  const [userType, setUserType] = useState(false);
  const [isSuperAdmin, setSuperAdmin] = useState(false);

  useEffect(() =>{    
    if(userdata!=null)
    { 
      setUserType(userdata.is_admin);
      setSuperAdmin(userdata.is_super_admin)
    }
  }, [userdata]);
 
 
  const onClickButton = (e, value) => {
    e.preventDefault();
 
    let v = paths.find((p) => p.path === value);
    setMainLayout(v);
    // navigate(`/${orgName}/${value}`);
    navigate(value);
  };
  
  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <h4>Torque Automation</h4>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
 
      <List component="nav">
        <Tooltip title="Dashboard" placement="right">
        <ListItemButton
          onClick={(e) => onClickButton(e, "/dashboard")}
          selected={mainLayout.path === "/dashboard"}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        </Tooltip>
       
        <Tooltip title="Users" placement="right">
        {userType && <ListItemButton
          onClick={(e) => onClickButton(e, "/user")}
          selected={mainLayout.path === "/user"}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>}
        </Tooltip>

        <Tooltip title="Setting" placement="right">
        {isSuperAdmin && <ListItemButton
          onClick={(e) => onClickButton(e, "/CeleryRestart")}
          selected={mainLayout.path === "/CeleryRestart"}
        >
          <ListItemIcon>
            <IoSettings />
          </ListItemIcon>
          <ListItemText primary="Setting" />
        </ListItemButton>}
        </Tooltip>
   

        <Tooltip title="Projects" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/project"}
          onClick={(e) => onClickButton(e, "/project")}
        >
          <ListItemIcon>
            <AspectRatioRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Projects" />
        </ListItemButton>
        </Tooltip>

        <Tooltip title="Project Environment" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/project_management"}
          onClick={(e) => onClickButton(e, "/project_management")}
        >
          <ListItemIcon>
            <AccountTreeIcon />
          </ListItemIcon>
          <ListItemText primary="Project Environment" />
        </ListItemButton>
        </Tooltip>

        <Tooltip title="Suites" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/suites"}
          onClick={(e) => onClickButton(e, "/suites")}
        >
          <ListItemIcon>
            <HorizontalSplitIcon />
          </ListItemIcon>
          <ListItemText primary="Suites" />
        </ListItemButton>
        </Tooltip>

        <Tooltip title="Test Case Reports" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/TestCaseReport"}
          onClick={(e) => onClickButton(e, "/TestCaseReport")}
        >
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Test Case Reports" />
        </ListItemButton>
        </Tooltip>

        <Tooltip title="Suite Reports" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/SuiteReport"}
          onClick={(e) => onClickButton(e, "/SuiteReport")}
        >
          <ListItemIcon>
            <FileCopyIcon />
          </ListItemIcon>
          
          <ListItemText primary="Suite Reports" />
        </ListItemButton>
        </Tooltip>

        <Tooltip title="Schedular Report" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/SchedularReport"}
          onClick={(e) => onClickButton(e, "/SchedularReport")}
        >
          <ListItemIcon>
            <FileCopyIcon />
          </ListItemIcon>
          
          <ListItemText primary="Schedule Job Report" />
        </ListItemButton>
        </Tooltip>

        <Tooltip title="Test Cases" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/testcases/all"}
          onClick={(e) => onClickButton(e, "/testcases/all")}
        >
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon> 
          <ListItemText primary="Test Cases" />
          
        </ListItemButton>
        </Tooltip>

        <Tooltip title="Assign Project" placement="right">
        {userType && <ListItemButton
          selected={mainLayout.path === "/add-user"}
          onClick={(e) => onClickButton(e, "/add-user")}>
          <ListItemIcon>
            <GroupAddIcon />
          </ListItemIcon> 
          <ListItemText primary="Assign Project" />
        </ListItemButton>}
        </Tooltip>

        <Tooltip title="Schedule Tasks" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/Scheduler"}
          onClick={(e) => onClickButton(e, "/Scheduler")}>
          <ListItemIcon>
            <ScheduleIcon />
          </ListItemIcon> 
          <ListItemText primary="Schedule Tasks" />
        </ListItemButton>
        </Tooltip>

        <Tooltip title="Files" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/files"}
          onClick={(e) => onClickButton(e, "/files")}>
          <ListItemIcon>
            <UploadFileIcon />
          </ListItemIcon> 
          <ListItemText primary="Documents" />
        </ListItemButton>
        </Tooltip>

        <Tooltip title="Stored Data" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/stored-data"}
          onClick={(e) => onClickButton(e, "/stored-data")}>
          <ListItemIcon>
            <CloudSyncIcon />
          </ListItemIcon> 
          <ListItemText primary="Stored Data" />
        </ListItemButton>
        </Tooltip>

          <Tooltip title="AI Test" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/ai-test"}
          onClick={(e) => onClickButton(e, "/ai-test")}>
          <ListItemIcon>
            <PsychologyIcon />
          </ListItemIcon> 
          <ListItemText primary="AI Test" />
        </ListItemButton>
        </Tooltip>  
        <Tooltip title="Jira" placement="right">
        <ListItemButton
          selected={mainLayout.path === "/Jira"}
          onClick={(e) => onClickButton(e, "/Jira")}>
          <ListItemIcon>
            <BugReportIcon />
          </ListItemIcon> 
          <ListItemText primary="Jira" />
        </ListItemButton>
        </Tooltip>  
      </List>
    </Drawer>
  );
};
 
const mapStateToProps = (state) => ({});
 
const mapDispatchToProps = {};
 
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);