import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableContainer, TableCell, Checkbox, Button, Card, CardContent, Select, MenuItem, Typography } from '@mui/material';
import { connect } from "react-redux";
import Divider from "@mui/material/Divider";
import { assignTestCaseToUsers, updateTestCaseAssignment, getTestCaseByUserId, getUserIdByProj } from "../redux/actions/assignTestCase";
import { toast } from 'react-toastify';
import '../styles/common.css';
import '../styles/custom.css';
import { testCaseList } from "../redux/actions/testcase";
import { useLocation } from "react-router-dom";


function UserTestCase({ getUserIdByProj, testList, testCaseList, usersList, assignTestCaseToUsers, updateTestCaseAssignment, getTestCaseByUserId, projectUser,assignedProject }) {
    const { state } = useLocation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [Proj, setProj] = useState(state);
    const [selectAll, setSelectAll] = useState(false);
    const [updatedUserList, setUpdatedUserList] = useState([])
    const [selectedUser, setSelectedUser] = useState('select');
    const [UpdatedTestCaseList,setUpdatedTestCaseList] = useState([])

    useEffect(() => {
        console.log("project",Proj)
        if (Proj) {
            getUserIdByProj(Proj?.proj_id);
            testCaseList(Proj?.proj_id, 0, 0, null, null, JSON.stringify({}));
        }
        getTestCaseByUserId();

    }, [Proj]
    )
    const handleRowSelect = (row, index) => {
        const selectedIndex = selectedRows.findIndex(selectedRow => selectedRow.case_id === row.case_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selectedRows, row];
        } else {
            newSelected = [...selectedRows.slice(0, selectedIndex), ...selectedRows.slice(selectedIndex + 1)];
        }
        setSelectedRows(newSelected);
        setSelectAll(newSelected?.length === testList?.length);
    };

    const isRowSelected = (row) => {
        return selectedRows.some(selectedRow => selectedRow.case_id === row.case_id);
    };

    // const assignUsers = () => {
    //     if (!selectedUser || selectedUser === 'select') {
    //         toast.error("Please select user before assignment");
    //         return;
    //     }
    //     else {
    //         if (selectedRows.length > 0) {
    //             console.log("Selected Rows",selectedRows)
    //             let successShown = false;
    //             selectedRows?.map((row, i) => {
    //                 let obj = {
    //                     user_id: selectedUser,
    //                     test_id: row.case_id
    //                 }
    //                 assignTestCaseToUsers(obj).then((res) => {
    //                     if (!successShown) {
    //                         toast.success("Project is assigned to user successfully");
    //                         successShown = true;
    //                     }
    //                 })
    //             })  
    //         } else {
    //             toast.error("Please select Testcase before assigning the users");
    //         }

    //     }
    // }

    const assignUsers = () => {
        if (!selectedUser || selectedUser === 'select') {
            toast.error("Please select user before assignment");
            return;
        }
        if (selectedRows.length > 0) {
            console.log("Selected Rows", selectedRows);
            const assignPromises = selectedRows.map((row) => {
                const obj = {
                    user_id: selectedUser,
                    test_id: row.case_id,
                    proj_id:Proj?.proj_id
                };
                return assignTestCaseToUsers(obj);
            });
    
            Promise.all(assignPromises)
                .then(() => {
                    toast.success("Project is assigned to user successfully");
                    testCaseList(Proj?.proj_id, 0, 0, null, null, JSON.stringify({}));
                })
                .catch((error) => {
                    toast.error("Error assigning project to user");
                    console.error(error);
                });
        } else {
            toast.error("Please select Testcase before assigning the users");
        }
    };
    

    const UpdatedUser = usersList
        ?.filter(user =>
            projectUser?.some(project => project.up_user_id_fk === user.u_id)  // Check if user is in the project list
        )
        .map((user, index) => {
            return user;  // Optionally return the user if needed
        });
    // if(testList){
    //     setUpdatedTestCaseList(testList)
    // }

    // Step 1: Map testList to unify the structure

    useEffect(() => {
        if (selectedRows?.length > 0) {
            console.log("Length", selectedRows?.length);
            
            // Update testList items that have matching case_id with commonElements
            const updatedTestList = testList.map(testItem => {
                const matchedItem = selectedRows.find(
                    commonItem => commonItem.case_id === testItem.case_id
                );
                if (matchedItem) {
                    return {
                        ...testItem,
                        assignuser: matchedItem.assignuser, // Add or update any other properties as needed
                    };
                }
                return testItem;
            });
    
            setUpdatedTestCaseList(updatedTestList);
        } else {
            setUpdatedTestCaseList(testList);
        }
    }, [selectedRows, testList]); // added dependencies
    

    
console.log("Updated Rows",UpdatedTestCaseList)
  

const handleSelectChange = (event) => {
    console.log("event",event)
    const id = event.target.value;
    setSelectedUser(id);
    setSelectedRows([]);


    

    // Assuming the comparison is between 'proj_name' from assignedProject and 'case_id' in testList
    const commonElements = testList?.filter(testItem =>
        assignedProject?.some(projectItem => 
            testItem.case_id === projectItem.ut_case_id  && projectItem.ut_user_id === id// Adjust the comparison logic
        )
    );
    const user = usersList.find(userItem => userItem.u_id === id);

    // Mapping the selected rows
    const newSelectedRows = commonElements.map(item => ({
        case_id: item.case_id,
        case_name: item.case_name,
        assignuser: user?.u_name  // Add assignuser if it exists or provide a default
    }));

    console.log("User Test Case ",UpdatedTestCaseList)

    // Set the new selected rows
    setSelectedRows(newSelectedRows);

    // Check if all testList items are selected
    setSelectAll(newSelectedRows.length === testList.length);
};

    // const handleSelectChange = (event) => {
    //     const id = event.target.value;
    //     setSelectedUser(id);
    //     setSelectedRows([]);
    //     const commonElements = testList?.filter(obj1 =>
    //         assignedProject?.some(obj2 => obj1.case_id === obj2.case_id)
    //     );

    //     const newSelectedRows = commonElements.map(item => ({
    //         case_id: item.case_id,
    //         case_name: item.case_name,
    //         assignuser: item.assignuser
    //     }));
    //     setSelectedRows(newSelectedRows);

    //     setSelectAll(newSelectedRows.length === testList.length);
    //     // getTestCaseByUserId()
    //     //     .then((res) => {
    //     //         console.log("res",res)
    //     //         const commonElements = testList?.filter(obj1 =>
    //     //             assignedProject?.some(obj2 => obj1.case_id === obj2.case_id)
    //     //         );

    //     //         const newSelectedRows = commonElements.map(item => ({
    //     //             case_id: item.case_id,
    //     //             case_name: item.case_name,
    //     //             assignuser: item.assignuser
    //     //         }));
    //     //         setSelectedRows(newSelectedRows);

    //     //         setSelectAll(newSelectedRows.length === testList.length);
    //     //     })
    //     //     .catch((error) => {
    //     //         console.error('Error:', error);
    //     //     });
    // };

    return (
        <div>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    User List
                </Typography>
                <Divider></Divider>
                <CardContent>
                    <div className='marginTop'>
                        <label className='label-bold marginRight'>User Name</label>
                        <Select className="form-control" onChange={handleSelectChange} value={selectedUser} sx={{ width: '280px' }}>
                            <MenuItem value="select" key="" disabled="true">Select User</MenuItem>
                            {UpdatedUser?.map((option, index) => (
                                <MenuItem value={option?.u_id} key={index}>{option?.u_name}</MenuItem>
                            ))}

                        </Select>
                        <br></br>
                    </div>
                    <br></br>
                </CardContent>
            </Card>
            <br></br>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    Assign Test Case To User
                    <Button sx={"float: right ; marginBottom: 2px"} onClick={assignUsers} disabled={selectedRows.length <= 0}> + Assign TestCase</Button>
                </Typography>
                <Divider></Divider>
                <CardContent>
                    <TableContainer component={Card} className='dashboard-table table td'>
                        <Table>
                            <TableHead className='th'>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Test Case Name</TableCell>
                                    <TableCell>Assigned To</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {UpdatedTestCaseList?.rows?.map((row, index) => (
                                    <TableRow
                                        key={row.case_id}
                                        hover
                                        onClick={() => handleRowSelect(row, index)}
                                        role="checkbox"
                                        aria-checked={isRowSelected(row)}
                                        selected={isRowSelected(row)}
                                    >

                                        <TableCell padding="checkbox">
                                            <Checkbox checked={isRowSelected(row)} />
                                        </TableCell>
                                        <TableCell >{row.testid}</TableCell>
                                        <TableCell >{row.case_name}</TableCell>
                                        <TableCell>{row.assignuser}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </div>
    );
}

const mapStateToProps = (state) => ({
    usersList: state?.assignUsers?.usersList,
    testList: state.auth?.testList,
    projectUser: state.assignTestCase?.ProjuserList?.rows,
    assignedProject: state.assignTestCase?.assignUsers

});

const mapDispatchToProps = {
    testCaseList,
    assignTestCaseToUsers,
    updateTestCaseAssignment,
    getTestCaseByUserId,
    getUserIdByProj
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTestCase);


