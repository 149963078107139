import { Button, Select, MenuItem, Card, Modal, Typography } from "@mui/material";
import { connect } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { runJob } from "../redux/actions/runJob";
import { StopJob } from "../redux/actions/stop";
import { modalPopUpStyleLarge } from '../styles/getLPTheme';
import { useLocation, UseState, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import "../styles/project.css";
import io from "socket.io-client";
import ENV_VAR from "../common";
import OngoingOperation from "../components/OngoingOperation";
import { getNotificationList } from "../redux/actions/notification";
import { getEnviromentList } from "../redux/actions/enviroment"
import { getPersonaList} from "../redux/actions/environmentPersona";




const SOCKET_SERVER_URL = `${ENV_VAR.API_HOST}`;

export const RunJob = ({ getEnviromentList, listOfEnviroment, runJob, StopJob, ongoingOperation, setOngoingOperation, NotificationList, getNotificationList,getPersonaList,ListData }) => {
  const socketRef = useRef(null);
  const { state } = useLocation();
  const location = useLocation();
  const [testData, setTestData] = useState("");
  const [progress, setProgress] = useState(0);
  let [items, setItems] = useState("");
  const [taskId, setTaskId] = useState(null);
  const [caseId, setCaseId] = useState(null);
  const [Stoptask, setStoptask] = useState('');
  const [Image, setImage] = useState(null);
  const [Message, setMessage] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [dropdownList, setDropdownList] = useState([]);
  const [Personadropdown, setPersonadropdown] = useState([]);
  const [selectedValue, setSelectedValue] = useState("select");
  const [selectedPersona, setSelectedPersona] = useState("select");
  const [progressBarColor, setProgressBarColor] = useState("ADD8E6")
  const handleClose = () => {
    setOpen(false);
    setIsVisible(true);
  };
  const updatePopUp = () => {
    setOpen(true);
    setIsVisible(false);
  }

  console.log("TESTDATA", testData)
  const updateDropdownList = () => {
    if (listOfEnviroment?.length > 0) {
      const values = listOfEnviroment
        .filter(item => item.proj_id === testData?.proj_id && item.is_active === true)
        .map(item => ({
          value: item.env_id,
          label: item.name,
        }));

      setDropdownList(values);
    }
  };

  const updatePersonaDropdownList = () => {
    console.log(selectedValue)
    if (ListData?.length > 0) {
      const values = ListData
        .filter(item => item.env_id === selectedValue)
        .map(item => ({
          value: item.persona_id,
          label: item.name,
        }));

        setPersonadropdown(values);
    }
  };


  console.log("ID",selectedValue)
  useEffect(() => {
    updateDropdownList();

  }, [listOfEnviroment, testData]);

  useEffect(() => {
    updatePersonaDropdownList();

  }, [ListData, selectedValue]);

  const handleChange = (event) => {
    console.log("handel chnage",event)
    setSelectedValue(event.target.value);
    // You can also handle additional logic here when the dropdown value changes
  };
  const handlePersonaChange = (event) => {
    console.log("handel chnage",event)
    setSelectedPersona(event.target.value);
    // You can also handle additional logic here when the dropdown value changes
  };



  useEffect(() => {
    // Initialize socket connection
    socketRef.current = io(SOCKET_SERVER_URL, {
      withCredentials: true,
    });

    const handleImgToReact = (data) => {
      setIsVisible(true);
      if (taskId === data.data[1]) {
        setImage("data:image/png;base64, " + data.data[0]);
      }
    };
   

    const handleMessageToReact = (data) => {
      if (caseId === data.data[2] && taskId === data.data[3]) {
        setStoptask(data.data[3]);
        setProgress(data.data[0]);
        getText(data.data[1]);
        setItems(data.data[1]);
        if (data.data[0] === 100) {
          socketRef.current.off("message_to_react", handleMessageToReact);
          socketRef.current.off("img_to_react", handleImgToReact);
          socketRef.current.disconnect();
          setIsDisabled(false);
          setOpen(false);
          setIsVisible(false);
          setOngoingOperation(null);
          setProgressBarColor("#4CBB17");

        }


      }
    };

    // Set up event listeners
    socketRef.current.on("img_to_react", handleImgToReact);
    socketRef.current.on("message_to_react", handleMessageToReact);

    // Clean up on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.off("img_to_react", handleImgToReact);
        socketRef.current.off("message_to_react", handleMessageToReact);
        socketRef.current.disconnect();
      }
    };
  }, [taskId, caseId, SOCKET_SERVER_URL]);




  useEffect(() => {
    if (state) {
      console.log("State",state)
      setTestData(state);
    }
    getPersonaList()
    getEnviromentList(0, 0, null, null, JSON.stringify({}));
  }, [state]);

  const navigateToTestCase = () => {
    let obj = {
      case_id: testData.case_id,
    };
    navigate(`/testcase`, { state: obj });
  };

  const parseLogString = (items) => {
    const lines = items.split("</br>");
    return lines.map((line, index) => (
      <li key={index} dangerouslySetInnerHTML={{ __html: line }} />
    ));
  };

  const getText = (items) => {
    const cleanedItems = items.replace(/running Step :/g, 'Step :');
    const lines = cleanedItems.split('</li>');
    const lastItem = lines[lines.length - 2];
    const startIndex = lastItem?.lastIndexOf('>') + 1;
    const liText = lastItem?.substring(startIndex)?.trim();
    setMessage(liText)
  };

  const startJobApi = () => {
    setOngoingOperation(null);
    if (socketRef.current) {
      socketRef.current.connect();
    };


    setIsDisabled(true);
    setImage(null);
    let obj={
      test_id:testData?.case_id,
      env_id:selectedValue.length>0 ? selectedValue :null,
      persona:selectedPersona.length>0 && selectedPersona !== 'select' ? selectedPersona:null
    }
    runJob(obj).then((res) => {
      setTaskId(res.taskId)
      // callApiAfterDelay();
    });

    setCaseId(testData?.case_id);
    setProgress(0);
    setItems("");
    setStoptask('');
  };


  useEffect(() => {
    if (taskId && caseId) {
      if (progress === 100) {
        setIsDisabled(false);
        setOngoingOperation(null);
        // callApiAfterDelay();
      } else {
        setIsDisabled(true);
        setOngoingOperation(JSON.stringify({ taskId: taskId, caseId: caseId, progress: progress, items: items, testData: testData, Image: Image, Stoptask: Stoptask }));
      }
    }
  }, [taskId, caseId, progress, items, testData, Image]);

  useEffect(() => {
    if (ongoingOperation) {
      let opr = JSON.parse(ongoingOperation);
      setTaskId(opr?.taskId);
      setCaseId(opr?.caseId);
      setProgress(opr?.progress);
      setItems(opr?.items);
      setTestData(opr?.testData);
      setImage(opr?.Image);
      setStoptask(opr?.Stoptask);
    }
  }, [ongoingOperation]);


  const stopJobApi = () => {
    StopJob(Stoptask, "TestCase");
  };

  // const progressBarColor = progress === 100 ? "#4CBB17" : "#ADD8E6";

  return (
    <div className="marginTop">
      <h3 className="label-bold">Test case name: {testData.case_name}</h3>
      <Card className="padding-runJob marginTop">
        <Box>
          <Button onClick={startJobApi} disabled={isDisabled}  >Start Job</Button>
          <Button onClick={navigateToTestCase} colorStyle="cancel">
            Back
          </Button>
        </Box>
        <br />
        {Stoptask && progress <= 90 ? <Box>
          <Button onClick={stopJobApi} colorStyle="cancel">Stop Job</Button>
        </Box> : null}
        <Box style={{ position: 'relative' }}>
          {isVisible && Image && (
            <div style={{ position: 'absolute', top: -85, right: 0, border: '2px solid #ced4da', cursor: 'pointer' }}>
              <div onClick={updatePopUp}>
                <img src={Image} alt="screenshot" style={{ height: '200px', width: '400px', padding: '10px' }} />
              </div>
            </div>
          )}
          {dropdownList?.length ?
            <div className='marginTop' style={{width:'300px'}}>
            <label className='label-bold marginRight'>Select Environment</label>
            <Select className="form-control" onChange={handleChange} value={selectedValue} >
              <MenuItem value="select" key="" >Select Environment</MenuItem>
              {dropdownList?.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>

          </div>: null}
          {Personadropdown?.length>0  && selectedValue !== '' && selectedValue !== 'select' ?
            <div className='marginTop' style={{width:'300px'}}>
            <label className='label-bold marginRight'>Select Persona</label>
            <Select className="form-control" onChange={handlePersonaChange} value={selectedPersona} >
              <MenuItem value="select" key="" >Select Persona</MenuItem>
              {Personadropdown?.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>

          </div>: null}
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          className="modalContainer">
          <Box
            sx={{
              ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
            }}
            noValidate
            autoComplete="off">

            <div className="modal-header">
              <img src={Image} alt="Screenshot" style={{ maxWidth: '100%', height: '100%' }} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">{Message}</Typography>
              <Typography gutterBottom textAlign="right" variant="h5">
                <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
              </Typography>
            </div>
          </Box>
        </Modal>

        <div>

          <br />
          <br />
          <br />
          <br />

          <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
            <p className="text-success">
              {taskId !== null && taskId !== undefined ? (
                `${progress}%`
              ) : (
                <p>Waiting for progress to start...</p>
              )}
            </p>


          </div>
          {taskId !== null && taskId !== undefined ? (
            <div
              className="progress "
              role="progressbar"
              style={{ height: "30px" }}
              aria-label="Success example"
              aria-valuenow="80"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                className="progress-bar progress-bar-striped "
                style={{
                  width: `${progress}%`,
                  backgroundColor: progressBarColor,
                  height: "100%",
                  transition: "width 0.5s ease-in-out",
                }}
              ></div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            width: `${progress}%`,
            backgroundColor: "green",
            height: "100%",
          }}
        ></div>
        {taskId !== null && taskId !== undefined ? (
          <div>
            <ul className="bullet-line-list">{parseLogString(items)}</ul>
            <div></div>
          </div>
        ) : (
          ""
        )}
      </Card>
      <div></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  validationList: state.auth.validationsList,
  runJob: state.auth.runJobStatus,
  StopJob: state.auth.stopJob,
  NotificationList: state.notification?.notification,
  listOfEnviroment: state?.Enviroment?.EnviromentList?.rows,
  ListData: state.Enviroment_Persona?.PersonaList?.rows,

});

const mapDispatchToProps = {
  runJob,
  StopJob,
  getNotificationList,
  getEnviromentList,
  getPersonaList
};

export default connect(mapStateToProps, mapDispatchToProps)(RunJob);
